define("manage/components/manage/selectable-list-group", ["exports", "@ember/component", "manage/components/manage/selectable-list", "@ember/template-factory"], function (_exports, _component, _selectableList, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canSelectAll}}
    <div class="selectable-list-selection-tools">
      <div class="form-check">
        <Input class="form-check-input chk-select-all" @type="checkbox" @checked={{this.allSelected}}
          id="select-all-records" />
  
        <label class="form-check-label" for="select-all-records">
          {{t "select all"}}
        </label>
      </div>
    </div>
  {{/if}}
  
  {{#each-in @value as |groupName list|}}
    <h4 class="mt-3 ms-2">
      {{groupName}}
    </h4>
  
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mb-5">
      {{#each list as |record|}}
        {{#unless record.isDeleted}}
          {{yield record this.selection this.onSelect}}
        {{/unless}}
      {{/each}}
    </div>
  {{/each-in}}
  
  {{#if this.selection}}
    <Manage::ActionsSelected @actions={{this.actions}} @selected={{this.selection}} @onAction={{this.action}}
      @onDelete={{this.delete}} class="w-100" />
  {{/if}}
  */
  {
    "id": "vA6yqD//",
    "block": "[[[41,[30,0,[\"canSelectAll\"]],[[[1,\"  \"],[10,0],[14,0,\"selectable-list-selection-tools\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"form-check-input chk-select-all\"],[24,1,\"select-all-records\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"allSelected\"]]]],null],[1,\"\\n\\n      \"],[10,\"label\"],[14,0,\"form-check-label\"],[14,\"for\",\"select-all-records\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"select all\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[30,1]],null],null,[[[1,\"  \"],[10,\"h4\"],[14,0,\"mt-3 ms-2\"],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mb-5\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2]],null]],null],null,[[[41,[51,[30,4,[\"isDeleted\"]]],[[[1,\"        \"],[18,5,[[30,4],[30,0,[\"selection\"]],[30,0,[\"onSelect\"]]]],[1,\"\\n\"]],[]],null]],[4]],null],[1,\"  \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"\\n\"],[41,[30,0,[\"selection\"]],[[[1,\"  \"],[8,[39,8],[[24,0,\"w-100\"]],[[\"@actions\",\"@selected\",\"@onAction\",\"@onDelete\"],[[30,0,[\"actions\"]],[30,0,[\"selection\"]],[30,0,[\"action\"]],[30,0,[\"delete\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@value\",\"list\",\"groupName\",\"record\",\"&default\"],false,[\"if\",\"input\",\"t\",\"each\",\"-each-in\",\"-track-array\",\"unless\",\"yield\",\"manage/actions-selected\"]]",
    "moduleName": "manage/components/manage/selectable-list-group.hbs",
    "isStrictMode": false
  });
  class ManageSelectableListGroupComponent extends _selectableList.default {
    get allValues() {
      const value = this.args.value ?? {};
      return Object.keys(value).flatMap(a => value[a]);
    }
  }
  _exports.default = ManageSelectableListGroupComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageSelectableListGroupComponent);
});