define("spaces/components/manage/page-section-editor", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @editor}}
    {{component (concat "editor/" @editor) element=@element value=@value onChange=@onChange deviceSize=@deviceSize space=@model.space model=@model gid=@gid}}
  {{/if}}
  */
  {
    "id": "v/G1A2A1",
    "block": "[[[41,[30,1],[[[1,\"  \"],[46,[28,[37,2],[\"editor/\",[30,1]],null],null,[[\"element\",\"value\",\"onChange\",\"deviceSize\",\"space\",\"model\",\"gid\"],[[30,2],[30,3],[30,4],[30,5],[30,6,[\"space\"]],[30,6],[30,7]]],null],[1,\"\\n\"]],[]],null]],[\"@editor\",\"@element\",\"@value\",\"@onChange\",\"@deviceSize\",\"@model\",\"@gid\"],false,[\"if\",\"component\",\"concat\"]]",
    "moduleName": "spaces/components/manage/page-section-editor.hbs",
    "isStrictMode": false
  });
  class ManagePageSectionEditorComponent extends _component2.default {}
  _exports.default = ManagePageSectionEditorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManagePageSectionEditorComponent);
});