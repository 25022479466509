define("spaces/components/editor/icon-container", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Frame @title={{t "option-panel.icon-container"}} ...attributes>
    <Input::Options::Panel @config={{this.config}} @space={{@space}} @deviceSize={{@deviceSize}} @value={{@value}} @onChange={{@onChange}} />
  </Input::Options::Frame>
  */
  {
    "id": "fIREMZMK",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\"],[[28,[37,1],[\"option-panel.icon-container\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@config\",\"@space\",\"@deviceSize\",\"@value\",\"@onChange\"],[[30,0,[\"config\"]],[30,2],[30,3],[30,4],[30,5]]],null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@space\",\"@deviceSize\",\"@value\",\"@onChange\"],false,[\"input/options/frame\",\"t\",\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/icon-container.hbs",
    "isStrictMode": false
  });
  class EditorIconContainerComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", [{
        type: "px",
        name: "height"
      }, {
        type: "margin"
      }, {
        type: "link",
        name: "destination"
      }]);
    }
  }
  _exports.default = EditorIconContainerComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorIconContainerComponent);
});