define("map/components/attributes/group-map-base", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "core/lib/geoJson", "core/lib/positionDetails", "@ember/runloop"], function (_exports, _component, _service, _tracking, _object, _geoJson, _positionDetails, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  /**
    Copyright: © 2015-2023 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AttributesGroupMapBaseComponent = (_class = class AttributesGroupMapBaseComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "fullscreen", _descriptor2, this);
      _initializerDefineProperty(this, "mapStyles", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "editMode", _descriptor5, this);
      _initializerDefineProperty(this, "_selectedBaseMap", _descriptor6, this);
      _initializerDefineProperty(this, "ignoreWebGl", _descriptor7, this);
    }
    get markerMessage() {
      if (this.details.type != 'manual') {
        return null;
      }
      return 'message-map-marker-move';
    }
    get extentLabelPosition() {
      const extent = this.args.extent.toExtent();
      const center = this.args.extent.center.coordinates;
      center[1] = extent[3];
      return center;
    }
    get extentStyle() {
      return this.mapStyles.extent(this.mapStyleElement);
    }
    layerSetupError(layer) {
      if (layer.type == 'MapBox') {
        this.ignoreWebGl = true;
      }
    }
    get baseMaps() {
      if (this.ignoreWebGl) {
        return this.args.baseMaps?.filter(a => !a.needsWebGl) ?? [];
      }
      return this.args.baseMaps ?? [];
    }
    get selectedBaseMap() {
      if (this._selectedBaseMap) {
        return this._selectedBaseMap;
      }
      return this.baseMaps?.[0] ?? null;
    }
    get mapExtent() {
      if (this.hasPositionInExtent || this.hasUpdatedPos) {
        const coordinates = this.args.position?.coordinates ?? [0, 0];
        const radius = 0.005;
        const topRight = [coordinates[0] - radius, coordinates[1] - radius];
        const bottomLeft = [coordinates[0] + radius, coordinates[1] + radius];
        return topRight.concat(bottomLeft);
      }
      if (this.args.extent) {
        const geoJson = new _geoJson.GeoJson(this.args.extent);
        const extent = geoJson.toOlFeature().getGeometry().getExtent();
        return extent;
      }
      return [-114, -41, 42, 72];
    }
    get details() {
      return this.args.details ?? {};
    }
    get position() {
      return new _geoJson.GeoJson(this.args.position ?? {});
    }
    get bearer() {
      let {
        access_token
      } = this.session?.get?.('data.authenticated') ?? null;
      if (!access_token) {
        return null;
      }
      return `Bearer ${access_token}`;
    }
    mapSetup(map) {
      this._map = map;
      this._mapView = map.getView();
    }
    edit() {
      this.editMode = true;
      this.fullscreen.freezeBody();
      this.prevPosition = new _geoJson.GeoJson(this.position);
      this.prevDetails = new _positionDetails.default(this.details);
      this.selectedPosition = null;
      this.selectedDetails = null;
    }
    selectBaseMap(value) {
      this._selectedBaseMap = value;
    }
    confirm() {
      this.editMode = false;
      this.fullscreen.unfreezeBody();
      this.hasUpdatedPos = true;
      this.updateMapSize();
    }
    dismiss() {
      this.editMode = false;
      this.fullscreen.unfreezeBody();
      this.args.onChange?.(this.prevPosition, this.prevDetails);
      (0, _runloop.later)(() => {
        this.updateMapSize();
      }, 100);
    }
    updateMapSize() {
      this._map?.updateSize?.();
    }
    updatePosition(map, view) {
      this._map = map;
      this._mapView = view;
    }
    setup() {
      this.oldType = this.details.type;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mapStyles", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "editMode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_selectedBaseMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ignoreWebGl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "layerSetupError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "layerSetupError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mapSetup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mapSetup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectBaseMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectBaseMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateMapSize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateMapSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePosition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePosition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  _exports.default = AttributesGroupMapBaseComponent;
});