define("spaces-view/components/view/source-attribute", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="source-attribute"
    {{on "props-update" this.updateProps}}
    data-path="{{@path}}.data.sourceAttribute"
    data-type="attribute"
    data-name={{@value.name}}
    data-gid={{@value.gid}}
    data-editor="source-attribute"
    ...attributes
  >
    {{#if this.attributeValue}}
      {{#if this.isBool}}
        {{yield (t (concat "boolean-" this.attributeValue))}}
      {{else if this.hasBlocks}}
        {{yield this.attributeValue to="blocks"}}
      {{else}}
        {{yield this.attributeValue}}
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "be/tU6qj",
    "block": "[[[11,0],[24,0,\"source-attribute\"],[16,\"data-path\",[29,[[30,1],\".data.sourceAttribute\"]]],[24,\"data-type\",\"attribute\"],[16,\"data-name\",[30,2,[\"name\"]]],[16,\"data-gid\",[30,2,[\"gid\"]]],[24,\"data-editor\",\"source-attribute\"],[17,3],[4,[38,0],[\"props-update\",[30,0,[\"updateProps\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"attributeValue\"]],[[[41,[30,0,[\"isBool\"]],[[[1,\"      \"],[18,4,[[28,[37,3],[[28,[37,4],[\"boolean-\",[30,0,[\"attributeValue\"]]],null]],null]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasBlocks\"]],[[[1,\"      \"],[18,5,[[30,0,[\"attributeValue\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,4,[[30,0,[\"attributeValue\"]]]],[1,\"\\n    \"]],[]]]],[]]]],[]],null],[13]],[\"@path\",\"@value\",\"&attrs\",\"&default\",\"&blocks\"],false,[\"on\",\"if\",\"yield\",\"t\",\"concat\"]]",
    "moduleName": "spaces-view/components/view/source-attribute.hbs",
    "isStrictMode": false
  });
  let ViewSourceAttributeComponent = (_class = class ViewSourceAttributeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value?.data?.sourceAttribute ?? {};
    }
    get attributeValue() {
      if (!this.args.record) {
        return null;
      }
      if (!this.value.field) {
        return null;
      }
      return (0, _object.get)(this.args.record, 'attributes.' + this.value.field);
    }
    get hasBlocks() {
      return Array.isArray(this.attributeValue?.blocks);
    }
    get isBool() {
      return typeof this.attributeValue == 'boolean';
    }
    updateProps(event) {
      this._value = event.detail.value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateProps", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProps"), _class.prototype)), _class);
  _exports.default = ViewSourceAttributeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewSourceAttributeComponent);
});