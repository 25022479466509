define("spaces/components/highlight", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template", "@ember/runloop", "@ember/string", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _template, _runloop, _string, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HighlightedElement = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _class3, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive --}}
  <div class="highlight" {{on "mouseover" this.updateFrames}} {{on "mouseout" this.removeFrames}} {{did-update this.setupSelection @selection}} {{did-insert this.setup}}>
    {{yield}}
  
    {{#each this.list as |value|}}
      <HighlightFrame @value={{value}} @onSelect={{@onSelect}}/>
    {{/each}}
  </div>
  */
  {
    "id": "NvJgG0EJ",
    "block": "[[[11,0],[24,0,\"highlight\"],[4,[38,0],[\"mouseover\",[30,0,[\"updateFrames\"]]],null],[4,[38,0],[\"mouseout\",[30,0,[\"removeFrames\"]]],null],[4,[38,1],[[30,0,[\"setupSelection\"]],[30,1]],null],[4,[38,2],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"list\"]]],null]],null],null,[[[1,\"    \"],[8,[39,6],null,[[\"@value\",\"@onSelect\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[13]],[\"@selection\",\"value\",\"@onSelect\",\"&default\"],false,[\"on\",\"did-update\",\"did-insert\",\"yield\",\"each\",\"-track-array\",\"highlight-frame\"]]",
    "moduleName": "spaces/components/highlight.hbs",
    "isStrictMode": false
  });
  let HighlightedElement = (_class = class HighlightedElement {
    constructor(rootElement, element, factor = 1, selectionType = "") {
      _initializerDefineProperty(this, "element", _descriptor, this);
      _initializerDefineProperty(this, "rootElement", _descriptor2, this);
      _initializerDefineProperty(this, "factor", _descriptor3, this);
      _initializerDefineProperty(this, "selectionType", _descriptor4, this);
      _initializerDefineProperty(this, "parent", _descriptor5, this);
      _initializerDefineProperty(this, "child", _descriptor6, this);
      _initializerDefineProperty(this, "titleWidth", _descriptor7, this);
      _initializerDefineProperty(this, "xTitle", _descriptor8, this);
      this.rootElement = rootElement;
      this.element = element;
      this.factor = factor;
      this.selectionType = selectionType;
    }
    get isHighlight() {
      return true;
    }
    get isPrimary() {
      return this.selectionType == "primary";
    }
    set isPrimary(value) {
      if (this.selectionType === "selection") {
        return;
      }
      this.selectionType = value ? "primary" : "";
      if (value) {
        for (const item of [...this.allParents, ...this.allChildren]) {
          item.selectionType = "";
        }
      }
    }
    get allParents() {
      const parents = [];
      let tmp = this.parent;
      while (tmp) {
        parents.push(tmp);
        tmp = tmp.parent;
      }
      return parents;
    }
    get allChildren() {
      const children = [];
      let tmp = this.child;
      while (tmp) {
        children.push(tmp);
        tmp = tmp.child;
      }
      return children;
    }
    get xTitleEnd() {
      return this.xTitle + this.titleWidth + 3;
    }
    get normalizedName() {
      return `${(0, _string.dasherize)(this.name || "unknown").replace(/[\W_]+/g, "-")}-${(0, _string.dasherize)(this.type || "unknown")}`;
    }
    hasTitleOnTheSameLine(other) {
      return Math.abs(this.top - other.top) < 9;
    }
    overlapsTitle(other) {
      if (!this.hasTitleOnTheSameLine(other)) {
        return false;
      }
      const endsBeforeOther = this.xTitleEnd <= other.xTitle;
      const startsAfterOther = this.xTitle >= other.xTitleEnd;
      if (endsBeforeOther && !startsAfterOther) {
        return false;
      }
      if (startsAfterOther && !endsBeforeOther) {
        return false;
      }
      return true;
    }
    updateXTitle() {
      this.xTitle = this.left;
      if (this.isPrimary) {
        return this.parent?.updateXTitle();
      }
      const allChildren = this.allChildren;
      const overlaps = allChildren.find(a => this.overlapsTitle(a));
      if (!overlaps || !this.allChildren.length) {
        this.xTitle = this.left;
        return this.parent?.updateXTitle();
      }
      const endX = allChildren.filter(a => this.hasTitleOnTheSameLine(a)).map(a => a.xTitleEnd).sort().reverse();
      for (const value of endX) {
        this.xTitle = value;
        const overlaps = allChildren.find(a => this.overlapsTitle(a));
        if (!overlaps) {
          break;
        }
      }
      this.parent?.updateXTitle();
    }
    get titleStyle() {
      return (0, _template.htmlSafe)(`left: ${this.xTitle * this.factor}px; top: ${(this.top - 18) * this.factor}px`);
    }
    get isHidden() {
      const frame = this.element.getBoundingClientRect();
      return frame.width == 0 || frame.height == 0;
    }
    get top() {
      if (this.isHidden) {
        return this.parent?.top;
      }
      const frame = this.element.getBoundingClientRect();
      const rootFrame = this.rootElement.getBoundingClientRect();
      return frame.top - rootFrame.top;
    }
    get left() {
      if (this.isHidden) {
        return this.parent?.left;
      }
      const frame = this.element.getBoundingClientRect();
      const rootFrame = this.rootElement.getBoundingClientRect();
      return frame.left - rootFrame.left;
    }
    get style() {
      const frame = this.element.getBoundingClientRect();
      return (0, _template.htmlSafe)(`width: ${frame.width * this.factor}px; height: ${frame.height * this.factor}px; left: ${this.left * this.factor}px; top: ${this.top * this.factor}px;`);
    }
    get name() {
      return this.element.attributes.getNamedItem("data-name").value;
    }
    get path() {
      return this.element.attributes.getNamedItem("data-path")?.value;
    }
    get type() {
      if (!this.element.hasAttribute("data-type")) {
        return "";
      }
      return this.element.attributes.getNamedItem("data-type").value;
    }
    get gid() {
      if (!this.element.hasAttribute("data-gid")) {
        return "";
      }
      return this.element.attributes.getNamedItem("data-gid").value;
    }
    get editor() {
      if (!this.element.hasAttribute("data-editor")) {
        return "";
      }
      return this.element.attributes.getNamedItem("data-editor").value;
    }
    get title() {
      return `${this.name} (${this.type})`;
    }
    toJSON() {
      const result = {};
      const keys = ["name", "type", "gid", "path", "editor"];
      for (const key of keys) {
        if (this[key]) {
          result[key] = this[key];
        }
      }
      return result;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rootElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "factor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectionType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "parent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "child", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "titleWidth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "xTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class);
  _exports.HighlightedElement = HighlightedElement;
  let HighlightComponent = (_class3 = class HighlightComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "rootElement", _descriptor9, this);
      _initializerDefineProperty(this, "list", _descriptor10, this);
    }
    setup(element) {
      this.rootElement = element;
      this.setupSelection();
    }
    setupSelection() {
      if (!this.rootElement) {
        return true;
      }
      if (!this.args.selection) {
        this.list = [];
        return;
      }
      const attributes = {};
      const fields = ["name", "type", "path", "gid"];
      for (const field of fields) {
        if (this.args.selection[field]) {
          attributes[field] = this.args.selection[field];
        }
      }
      const selector = Object.keys(attributes).map(a => `[data-${a}="${attributes[a]}"]`).join("");
      const element = this.rootElement.querySelector(selector);
      this.list = [new HighlightedElement(this.rootElement, element, this.args.factor, "selection")];
      this.list[0].updateXTitle();
    }
    hiddenChildren(element) {
      let result = [];
      for (const child of element.children) {
        const frame = child.getBoundingClientRect();
        const isHidden = frame.width == 0 || frame.height == 0;
        if (child.hasAttribute("data-name") && isHidden) {
          result.push(child);
        }
      }
      return result;
    }
    updateFrames(ev) {
      if (this.args.selection) {
        return;
      }
      let currentNode = ev.target;
      (0, _runloop.cancel)(this.removeTimer);
      if (currentNode.parentElement?.classList.contains("highlight-title") || currentNode.classList.contains("highlight-title")) {
        return;
      }
      if (ev.altKey) {
        return;
      }
      let nodes = [];
      while (currentNode != this.rootElement) {
        nodes.push(...this.hiddenChildren(currentNode));
        nodes.push(currentNode);
        currentNode = currentNode.parentElement;
      }
      nodes = nodes.filter(a => a.hasAttribute("data-name")).reverse();
      this.list = nodes.map(a => new HighlightedElement(this.rootElement, a, this.args.factor));
      if (!this.list.length) {
        return;
      }
      const lastElement = this.list[this.list.length - 1];
      if (!lastElement) {
        return;
      }
      lastElement.isPrimary = true;
      for (let i = 1; i < this.list.length; i++) {
        this.list[i].parent = this.list[i - 1];
      }
      for (let i = 0; i < this.list.length - 1; i++) {
        this.list[i].child = this.list[i + 1];
      }
      (0, _runloop.later)(() => {
        lastElement.updateXTitle();
      }, 10);
    }
    removeFrames(ev) {
      if (this.args.selection) {
        return;
      }
      if (ev.altKey) {
        return;
      }
      this.removeTimer = (0, _runloop.later)(() => {
        this.list = [];
        this.removeTimer = null;
      }, 10);
    }
  }, (_descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "rootElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "list", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "setup"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "setupSelection", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "setupSelection"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "updateFrames", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "updateFrames"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "removeFrames", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "removeFrames"), _class3.prototype)), _class3);
  _exports.default = HighlightComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HighlightComponent);
});