define("spaces-view/components/view/event/dates", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "luxon", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _luxon, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <View::Base::SourceRecord @model={{@model}} @value={{@value}} @onLoad={{this.loaded}}>
    <div class="page-col-event-dates">
      {{#each this.niceEntries as | entry |}}
        <TextWithOptions @tag="div" class="entry" @deviceSize={{@deviceSize}} @options={{@value.data.style}}>{{!
          }}<MarkDown @value={{capitalize entry}}/>{{!
        }}</TextWithOptions>
      {{/each}}
    </div>
  </View::Base::SourceRecord>
  */
  {
    "id": "ek3pDrH/",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@value\",\"@onLoad\"],[[30,1],[30,2],[30,0,[\"loaded\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"page-col-event-dates\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"niceEntries\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],[[24,0,\"entry\"]],[[\"@tag\",\"@deviceSize\",\"@options\"],[\"div\",[30,4],[30,2,[\"data\",\"style\"]]]],[[\"default\"],[[[[8,[39,4],null,[[\"@value\"],[[28,[37,5],[[30,3]],null]]],null]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\",\"@value\",\"entry\",\"@deviceSize\"],false,[\"view/base/source-record\",\"each\",\"-track-array\",\"text-with-options\",\"mark-down\",\"capitalize\"]]",
    "moduleName": "spaces-view/components/view/event/dates.hbs",
    "isStrictMode": false
  });
  let PageColEventDatesComponent = (_class = class PageColEventDatesComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "record", _descriptor2, this);
    }
    addDateKeys(prefix, dest, date) {
      dest[`${prefix}Time`] = date.toLocaleString(_luxon.DateTime.TIME_SIMPLE);
      dest[`${prefix}Date`] = date.toLocaleString(_luxon.DateTime.DATE_MED);
      dest[`${prefix}Y`] = date.toFormat('yyyy');
      dest[`${prefix}M`] = date.toFormat('MMMM');
      dest[`${prefix}D`] = date.toFormat('d');
      dest[`${prefix}DWeek`] = date.toFormat('EEEE');
    }
    toNiceString(entry) {
      let vars = {};
      this.addDateKeys('begin', vars, entry.begin);
      this.addDateKeys('end', vars, entry.end);
      if (entry.intervalEnd) {
        this.addDateKeys('intervalEnd', vars, entry.intervalEnd);
      }
      let intervalT = `${vars.beginD} ${vars.beginM}` == `${vars.endD} ${vars.endM}` ? `event_date.interval_same_day` : `event_date.interval_days_${entry.repetition}`;
      return this.intl.t(`event_date.repeats_${entry.repetition}`, vars) + ', ' + this.intl.t(intervalT, vars);
    }
    get niceEntries() {
      return this.record?.entries?.map(a => this.toNiceString(a));
    }
    loaded(record) {
      this.record = record;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "record", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loaded"), _class.prototype)), _class);
  _exports.default = PageColEventDatesComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageColEventDatesComponent);
});