define("manage/components/theme-wormhole", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="d-none" {{did-insert this.setup}} {{did-update this.setup @section this.theme.name}}></div>
  */
  {
    "id": "0Rf5/J3R",
    "block": "[[[11,0],[24,0,\"d-none\"],[4,[38,0],[[30,0,[\"setup\"]]],null],[4,[38,1],[[30,0,[\"setup\"]],[30,1],[30,0,[\"theme\",\"name\"]]],null],[12],[13]],[\"@section\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "manage/components/theme-wormhole.hbs",
    "isStrictMode": false
  });
  let ThemeWormholeComponent = (_class = class ThemeWormholeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "theme", _descriptor, this);
    }
    setup() {
      const body = window.document.querySelector("body");
      if (this.args.section != "manage") {
        body.dataset.bsTheme = "";
        return;
      }
      body.dataset.bsTheme = this.theme.name;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "theme", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  _exports.default = ThemeWormholeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ThemeWormholeComponent);
});