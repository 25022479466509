define("manage/components/input/manage/calendar-entry-list", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "models/transforms/calendar-entry-list", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _calendarEntryList, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::ContainerGroup
    @isMainValue={{true}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    @onEdit={{@onEdit}}
    @title={{@title}}
    @editablePanel={{@editablePanel}}
    @savingPanel={{@savingPanel}}
    as |editMode|
  >
    {{#if editMode}}
      <Input::CalendarEntryList @value={{this.value}} @onChange={{this.change}} />
    {{else}}
      <CalendarEntryList @value={{this.value}} />
    {{/if}}
  </Input::ContainerGroup>
  */
  {
    "id": "a5woqNYU",
    "block": "[[[8,[39,0],null,[[\"@isMainValue\",\"@onSave\",\"@onCancel\",\"@onEdit\",\"@title\",\"@editablePanel\",\"@savingPanel\"],[true,[30,0,[\"save\"]],[30,0,[\"cancel\"]],[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,0,[\"value\"]],[30,0,[\"change\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]]]],[5]]]]]],[\"@onEdit\",\"@title\",\"@editablePanel\",\"@savingPanel\",\"editMode\"],false,[\"input/container-group\",\"if\",\"input/calendar-entry-list\",\"calendar-entry-list\"]]",
    "moduleName": "manage/components/input/manage/calendar-entry-list.hbs",
    "isStrictMode": false
  });
  let InputManageCalendarEntryListComponent = (_class = class InputManageCalendarEntryListComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value?.map(a => new _calendarEntryList.CalendarEntry(a)) ?? [];
    }
    change(value) {
      this._value = value;
    }
    save() {
      return this.args.onSave(this.value);
    }
    cancel() {
      this._value = null;
      return this.args.onCancel();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  _exports.default = InputManageCalendarEntryListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputManageCalendarEntryListComponent);
});