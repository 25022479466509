define("ember-table/-private/utils/observer", ["exports", "@ember/debug", "@ember/object", "@ember/object/observers"], function (_exports, _debug, _object, _observers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeObserver = _exports.observer = _exports.addObserver = void 0;
  // eslint-disable-next-line no-restricted-imports

  // eslint-disable-next-line no-restricted-imports

  const USE_ASYNC_OBSERVERS = true;
  function asyncObserver(...args) {
    let fn = args.pop();
    let dependentKeys = args;
    let sync = false;
    return (0, _object.observer)({
      dependentKeys,
      fn,
      sync
    });
  }
  function asyncAddObserver(...args) {
    let obj, path, target, method;
    let sync = false;
    obj = args[0];
    path = args[1];
    (false && !(args.length === 3 || args.length === 4) && (0, _debug.assert)(`Expected 3 or 4 args for addObserver, got ${args.length}`, args.length === 3 || args.length === 4));
    if (args.length === 3) {
      target = null;
      method = args[2];
    } else if (args.length === 4) {
      target = args[2];
      method = args[3];
    }
    return (0, _observers.addObserver)(obj, path, target, method, sync);
  }
  function asyncRemoveObserver(...args) {
    let obj, path, target, method;
    let sync = false;
    obj = args[0];
    path = args[1];
    (false && !(args.length === 3 || args.length === 4) && (0, _debug.assert)(`Expected 3 or 4 args for addObserver, got ${args.length}`, args.length === 3 || args.length === 4));
    if (args.length === 3) {
      target = null;
      method = args[2];
    } else {
      target = args[2];
      method = args[3];
    }
    return (0, _observers.removeObserver)(obj, path, target, method, sync);
  }
  const observer = USE_ASYNC_OBSERVERS ? asyncObserver : _object.observer;
  _exports.observer = observer;
  const addObserver = USE_ASYNC_OBSERVERS ? asyncAddObserver : _observers.addObserver;
  _exports.addObserver = addObserver;
  const removeObserver = _observers.removeObserver ? asyncRemoveObserver : _observers.removeObserver;
  _exports.removeObserver = removeObserver;
});