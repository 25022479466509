define("spaces/components/editor/list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="manage-editors-list" ...attributes>
    <Input::Options::Frame class="text-list" @title={{capitalize (t "option-panel.text-list")}}>
      <Input::StringList @value={{this.textList}} @onChange={{fn this.change "_textList"}} />
    </Input::Options::Frame>
  
    <Input::Options::Frame class='text-style' @title={{t 'option-panel.text-style'}}>
      <Editor::Properties::Text @value={{this.textStyle}} @deviceSize={{@deviceSize}}
        @onDeviceSizeChange={{@onDeviceSizeChange}} @onChange={{fn this.change "_textStyle"}}
        @enableTextValue={{false}} />
    </Input::Options::Frame>
  
    <Input::Options::Frame class='icon-style' @title={{t 'option-panel.icon-style'}}>
      <Input::Options::Panel @config={{this.iconStyleConfig}} @deviceSize={{@deviceSize}} @value={{this.iconStyle}}
        @onChange={{fn this.change "_iconStyle"}} />
    </Input::Options::Frame>
  </div>
  */
  {
    "id": "HBWVX0nN",
    "block": "[[[11,0],[24,0,\"manage-editors-list\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"text-list\"]],[[\"@title\"],[[28,[37,1],[[28,[37,2],[\"option-panel.text-list\"],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@value\",\"@onChange\"],[[30,0,[\"textList\"]],[28,[37,4],[[30,0,[\"change\"]],\"_textList\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],[[24,0,\"text-style\"]],[[\"@title\"],[[28,[37,2],[\"option-panel.text-style\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@value\",\"@deviceSize\",\"@onDeviceSizeChange\",\"@onChange\",\"@enableTextValue\"],[[30,0,[\"textStyle\"]],[30,2],[30,3],[28,[37,4],[[30,0,[\"change\"]],\"_textStyle\"],null],false]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,0],[[24,0,\"icon-style\"]],[[\"@title\"],[[28,[37,2],[\"option-panel.icon-style\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@config\",\"@deviceSize\",\"@value\",\"@onChange\"],[[30,0,[\"iconStyleConfig\"]],[30,2],[30,0,[\"iconStyle\"]],[28,[37,4],[[30,0,[\"change\"]],\"_iconStyle\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@deviceSize\",\"@onDeviceSizeChange\"],false,[\"input/options/frame\",\"capitalize\",\"t\",\"input/string-list\",\"fn\",\"editor/properties/text\",\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/list.hbs",
    "isStrictMode": false
  });
  let ManageEditorsListComponent = (_class = class ManageEditorsListComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_textStyle", _descriptor, this);
      _initializerDefineProperty(this, "_iconStyle", _descriptor2, this);
      _initializerDefineProperty(this, "_textList", _descriptor3, this);
      _defineProperty(this, "iconStyleConfig", [{
        name: "name",
        options: ["circle-check", "check", "circle", "circle-dot", "plus", "minus", "hashtag", "asterisk", "check-double", "square-check", "arrow-right", "circle-arrow-right", "circle-right"]
      }, {
        name: "size",
        options: ["1", "2", "3", "4"]
      }, {
        name: "color",
        type: "color"
      }]);
    }
    get textList() {
      if (this._textList) {
        return this._textList;
      }
      return this.args.value?.data?.textList;
    }
    get textStyle() {
      if (this._textStyle) {
        return this._textStyle;
      }
      return this.args.value?.data?.textStyle;
    }
    get iconStyle() {
      if (this._iconStyle) {
        return this._iconStyle;
      }
      return this.args.value?.data?.iconStyle;
    }
    change(name, value) {
      this[name] = value;
      return this.triggerChange();
    }
    triggerChange() {
      return this.args.onChange({
        textList: this.textList,
        textStyle: this.textStyle,
        iconStyle: this.iconStyle
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_textStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_iconStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_textList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  _exports.default = ManageEditorsListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageEditorsListComponent);
});