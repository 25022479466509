define("manage/components/dropdown-theme", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _service, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DropdownList class="dropdown-theme" @isActive={{@isActive}} @icon={{this.icon}}>
    <button class="dropdown-item dropdown-item-auto" type="button" {{on "click" (fn this.setTheme "auto")}}>
      <FaIcon @icon="circle-half-stroke" /> {{capitalize (t "auto")}}
    </button>
  
    <button class="dropdown-item dropdown-item-light" type="button" {{on "click" (fn this.setTheme "light")}}>
      <FaIcon @icon="sun" /> {{capitalize (t "light")}}
    </button>
  
    <button class="dropdown-item dropdown-item-dark" type="button" {{on "click" (fn this.setTheme "dark")}}>
      <FaIcon @icon="moon" /> {{capitalize (t "dark")}}
    </button>
  </DropdownList>
  */
  {
    "id": "qTSviJas",
    "block": "[[[8,[39,0],[[24,0,\"dropdown-theme\"]],[[\"@isActive\",\"@icon\"],[[30,1],[30,0,[\"icon\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"dropdown-item dropdown-item-auto\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"setTheme\"]],\"auto\"],null]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[\"circle-half-stroke\"]],null],[1,\" \"],[1,[28,[35,4],[[28,[37,5],[\"auto\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"button\"],[24,0,\"dropdown-item dropdown-item-light\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"setTheme\"]],\"light\"],null]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[\"sun\"]],null],[1,\" \"],[1,[28,[35,4],[[28,[37,5],[\"light\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"button\"],[24,0,\"dropdown-item dropdown-item-dark\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"setTheme\"]],\"dark\"],null]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[\"moon\"]],null],[1,\" \"],[1,[28,[35,4],[[28,[37,5],[\"dark\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@isActive\"],false,[\"dropdown-list\",\"on\",\"fn\",\"fa-icon\",\"capitalize\",\"t\"]]",
    "moduleName": "manage/components/dropdown-theme.hbs",
    "isStrictMode": false
  });
  let DropdownThemeComponent = (_class = class DropdownThemeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "theme", _descriptor, this);
      _defineProperty(this, "icons", {
        auto: 'circle-half-stroke',
        light: 'sun',
        dark: 'moon'
      });
    }
    get icon() {
      return this.icons[this.theme.preferred];
    }
    setTheme(name) {
      this.theme.preferred = name;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "theme", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setTheme", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setTheme"), _class.prototype)), _class);
  _exports.default = DropdownThemeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownThemeComponent);
});