define("manage/components/input/manage/search-options", ["exports", "@ember/component", "@glimmer/component", "models/transforms/space-search-options", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _spaceSearchOptions, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::ContainerGroup
    @isMainValue={{true}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    @onEdit={{@onEdit}}
    @title={{@title}}
    @editablePanel={{@editablePanel}}
    @savingPanel={{@savingPanel}}
    as |editMode|
  >
    <div class="row">
      {{#each this.pairs as |pair|}}
        <div class="col-6 col-md-3 mb-3">
          <div class="text-model-name me-2">
            {{pair.key}}
          </div>
          <div class="fw-bold text-model-search-value">
  
            {{#if editMode}}
              <Input::Select
                @list={{this.options}}
                @value={{pair.value}}
                @onChange={{fn this.change pair.key}}
                class="value-{{dasherize pair.key}}"
              />
            {{else}}
              {{#if pair.value}}
                {{t "yes"}}
              {{else}}
                {{t "no"}}
              {{/if}}
            {{/if}}
          </div>
        </div>
      {{/each}}
    </div>
  </Input::ContainerGroup>
  */
  {
    "id": "VeNfeAHt",
    "block": "[[[8,[39,0],null,[[\"@isMainValue\",\"@onSave\",\"@onCancel\",\"@onEdit\",\"@title\",\"@editablePanel\",\"@savingPanel\"],[true,[30,0,[\"save\"]],[30,0,[\"cancel\"]],[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"pairs\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"col-6 col-md-3 mb-3\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"text-model-name me-2\"],[12],[1,\"\\n          \"],[1,[30,6,[\"key\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"fw-bold text-model-search-value\"],[12],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"            \"],[8,[39,4],[[16,0,[29,[\"value-\",[28,[37,5],[[30,6,[\"key\"]]],null]]]]],[[\"@list\",\"@value\",\"@onChange\"],[[30,0,[\"options\"]],[30,6,[\"value\"]],[28,[37,6],[[30,0,[\"change\"]],[30,6,[\"key\"]]],null]]],null],[1,\"\\n\"]],[]],[[[41,[30,6,[\"value\"]],[[[1,\"              \"],[1,[28,[35,7],[\"yes\"],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[35,7],[\"no\"],null]],[1,\"\\n\"]],[]]]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[6]],null],[1,\"  \"],[13],[1,\"\\n\"]],[5]]]]]],[\"@onEdit\",\"@title\",\"@editablePanel\",\"@savingPanel\",\"editMode\",\"pair\"],false,[\"input/container-group\",\"each\",\"-track-array\",\"if\",\"input/select\",\"dasherize\",\"fn\",\"t\"]]",
    "moduleName": "manage/components/input/manage/search-options.hbs",
    "isStrictMode": false
  });
  let InputManageSearchOptionsComponent = (_class = class InputManageSearchOptionsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "options", [{
        id: true,
        name: 'yes'
      }, {
        id: false,
        name: 'no'
      }]);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value ?? new _spaceSearchOptions.SpaceSearchOptions();
    }
    get pairs() {
      const list = [];
      for (const key of _spaceSearchOptions.SpaceSearchOptions.fields) {
        list.push({
          key,
          value: this.args.value?.[key] ?? false
        });
      }
      return list;
    }
    change(key, newValue) {
      let value = this.value;
      value[key] = newValue.id;
      this._value = value;
    }
    cancel() {
      this._value = null;
      return this.args.onCancel();
    }
    save() {
      return this.args.onSave?.(this.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = InputManageSearchOptionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputManageSearchOptionsComponent);
});