define("spaces-view/components/state-filters/map-feature", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "core/lib/page-state", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _tracking, _pageState, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="state-filters-map-feature"
    {{did-insert this.setup}}
    {{did-update this.setup @state}}
    ...attributes
  >
    {{#if this.hasSearch}}
      <Filters::SearchOptions
        @niceValue={{this.niceValue}}
        @title={{this.title}}
        @placeholder={{this.placeholder}}
        @onReset={{this.reset}}
        @onSearch={{this.search}}
      >
        {{#if this.results}}
          <hr class="m-0" />
  
          <div class="list-group list-group-flush">
            {{#each this.results as |record|}}
              <button
                type="button"
                class="list-group-item list-group-item-action"
                {{on "click" (fn this.select record)}}
              >
                {{record.name}}
              </button>
            {{/each}}
          </div>
        {{/if}}
      </Filters::SearchOptions>
    {{else}}
      <Filters::Options
        @value={{this.niceValue}}
        @title={{this.title}}
        @onChange={{this.reset}}
        class="limit-height"
      >
        <div class="list-group list-group-flush">
          {{#each this.results as |value|}}
            <button
              type="button"
              class="list-group-item list-group-item-action"
              {{on "click" (fn this.select value)}}
            >
              {{value.name}}
            </button>
          {{/each}}
        </div>
      </Filters::Options>
    {{/if}}
  </div>
  */
  {
    "id": "6GJEPBNv",
    "block": "[[[11,0],[24,0,\"state-filters-map-feature\"],[17,1],[4,[38,0],[[30,0,[\"setup\"]]],null],[4,[38,1],[[30,0,[\"setup\"]],[30,2]],null],[12],[1,\"\\n\"],[41,[30,0,[\"hasSearch\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@niceValue\",\"@title\",\"@placeholder\",\"@onReset\",\"@onSearch\"],[[30,0,[\"niceValue\"]],[30,0,[\"title\"]],[30,0,[\"placeholder\"]],[30,0,[\"reset\"]],[30,0,[\"search\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"results\"]],[[[1,\"        \"],[10,\"hr\"],[14,0,\"m-0\"],[12],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"list-group list-group-flush\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"            \"],[11,\"button\"],[24,0,\"list-group-item list-group-item-action\"],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"select\"]],[30,3]],null]],null],[12],[1,\"\\n              \"],[1,[30,3,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,8],[[24,0,\"limit-height\"]],[[\"@value\",\"@title\",\"@onChange\"],[[30,0,[\"niceValue\"]],[30,0,[\"title\"]],[30,0,[\"reset\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"list-group list-group-flush\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"          \"],[11,\"button\"],[24,0,\"list-group-item list-group-item-action\"],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"select\"]],[30,4]],null]],null],[12],[1,\"\\n            \"],[1,[30,4,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@state\",\"record\",\"value\"],false,[\"did-insert\",\"did-update\",\"if\",\"filters/search-options\",\"each\",\"-track-array\",\"on\",\"fn\",\"filters/options\"]]",
    "moduleName": "spaces-view/components/state-filters/map-feature.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2023 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let StateFiltersMapFeatureComponent = (_class = class StateFiltersMapFeatureComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "clickOutside", _descriptor2, this);
      _initializerDefineProperty(this, "results", _descriptor3, this);
      _initializerDefineProperty(this, "_feature", _descriptor4, this);
    }
    select(value) {
      this._feature = value;
      const newState = (0, _pageState.toPageState)({
        ...this.state,
        ffid: value?.id
      });
      this.args.onChangeState?.(newState);
      this.clickOutside.unsubscribe();
    }
    get hasSearch() {
      return this.args.options?.['with-search'];
    }
    get limit() {
      return this.hasSearch ? 10 : 0;
    }
    get title() {
      return this.args.options?.label;
    }
    get placeholder() {
      return this.args.options?.['placeholder-text'];
    }
    get state() {
      return (0, _pageState.fromPageState)(this.args.state);
    }
    get feature() {
      if (this._feature) {
        return this._feature;
      }
      if (!this.state.ffid) {
        return null;
      }
      return this.store.peekRecord('feature', this.state.ffid);
    }
    get niceValue() {
      return this.feature?.name;
    }
    reset() {
      this._feature = null;
      const newState = (0, _pageState.toPageState)({
        ...this.state,
        ffid: undefined
      });
      this.args.onChangeState?.(newState);
    }
    async loadFeatures(query) {
      query.sortBy = 'name';
      query.sortOrder = 'asc';
      if (this.limit) {
        query.limit = this.limit;
      }
      if (typeof this.args.options?.map == 'string') {
        query.map = this.args.options.map;
      }
      if (typeof this.args.options?.map?.id == 'string') {
        query.map = this.args.options.map.id;
      }
      this.queryTimer = (0, _runloop.later)(async () => {
        try {
          this.results = await this.store.query('feature', query);
        } catch (err) {
          this.results = [];
        }
      }, 500);
    }
    async search(term) {
      (0, _runloop.cancel)(this.queryTimer);
      if (!term || term.length < 4) {
        this.results = [];
        return;
      }
      const query = {
        term
      };
      return this.loadFeatures(query);
    }
    async setup() {
      if (this.state.ffid && !this.feature) {
        this._feature = await this.store.findRecord('feature', this.state.ffid);
      } else {
        this._feature = null;
      }
      if (!this.hasSearch) {
        this.loadFeatures({});
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clickOutside", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "results", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_feature", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  _exports.default = StateFiltersMapFeatureComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateFiltersMapFeatureComponent);
});