define("spaces/components/editor/button-style", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Panel @config={{this.panel}} @value={{this.style}} @onChange={{this.changeStyleClasses}} />
  */
  {
    "id": "vM6Tl3bM",
    "block": "[[[8,[39,0],null,[[\"@config\",\"@value\",\"@onChange\"],[[30,0,[\"panel\"]],[30,0,[\"style\"]],[30,0,[\"changeStyleClasses\"]]]],null]],[],false,[\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/button-style.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2023 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let EditorButtonStyleComponent = (_class = class EditorButtonStyleComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_style", _descriptor, this);
      _defineProperty(this, "panel", [{
        type: "button-style"
      }, {
        type: "width-percentage"
      }, {
        type: "margin"
      }]);
    }
    get value() {
      return this.args.value ?? {};
    }
    get style() {
      return this._style ?? this.value ?? {};
    }
    triggerChange() {
      this.args.onChange?.(this.style);
    }
    changeStyleClasses(value) {
      this._style = this.style;
      this._style.classes = value.classes;
      this.triggerChange();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_style", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeStyleClasses", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeStyleClasses"), _class.prototype)), _class);
  _exports.default = EditorButtonStyleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorButtonStyleComponent);
});