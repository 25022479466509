define("spaces/components/editor/source-stats", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='manage-editors-stats'>
    <Input::Options::Frame @title={{t 'option-panel.source' }} ...attributes>
      <div class='mb-3'>
        <div class='form-floating'>
          <Input::Select class='stats-name' id='{{this.elementId}}-stats-name' @list={{this.statsList}}
            @value={{@value.id}} @onChange={{this.sourceChanged}} />
          <label for='{{this.elementId}}-stats-name'>{{t 'option-panel.stats'}}</label>
        </div>
      </div>
    </Input::Options::Frame>
  </div>
  */
  {
    "id": "0svJ17UN",
    "block": "[[[10,0],[14,0,\"manage-editors-stats\"],[12],[1,\"\\n  \"],[8,[39,0],[[17,1]],[[\"@title\"],[[28,[37,1],[\"option-panel.source\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"mb-3\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-floating\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,0,\"stats-name\"],[16,1,[29,[[30,0,[\"elementId\"]],\"-stats-name\"]]]],[[\"@list\",\"@value\",\"@onChange\"],[[30,0,[\"statsList\"]],[30,2,[\"id\"]],[30,0,[\"sourceChanged\"]]]],null],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[29,[[30,0,[\"elementId\"]],\"-stats-name\"]]],[12],[1,[28,[35,1],[\"option-panel.stats\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\"],false,[\"input/options/frame\",\"t\",\"input/select\"]]",
    "moduleName": "spaces/components/editor/source-stats.hbs",
    "isStrictMode": false
  });
  let EditorSourceStatsComponent = (_class = class EditorSourceStatsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "statsList", [{
        id: "published-campaigns",
        name: "published campaigns"
      }, {
        id: "campaign-answer-contributors",
        name: "campaign answer contributors"
      }, {
        id: "campaign-contributions",
        name: "campaign contributions"
      }]);
    }
    sourceChanged(value) {
      this.args.onChange({
        model: "stat",
        id: value.id
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "sourceChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sourceChanged"), _class.prototype)), _class);
  _exports.default = EditorSourceStatsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorSourceStatsComponent);
});