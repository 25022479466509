define("spaces/components/editor/link-named", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Editor::Properties::LinkNamed @value={{@value}} @space={{@space}} @onChange={{@onChange}} />
  */
  {
    "id": "rtqTBfZ4",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@space\",\"@onChange\"],[[30,1],[30,2],[30,3]]],null]],[\"@value\",\"@space\",\"@onChange\"],false,[\"editor/properties/link-named\"]]",
    "moduleName": "spaces/components/editor/link-named.hbs",
    "isStrictMode": false
  });
  class EditorLinkNamedComponent extends _component2.default {}
  _exports.default = EditorLinkNamedComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorLinkNamedComponent);
});