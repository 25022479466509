define("manage/components/manage/selectable-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/array", "@glimmer/tracking", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _array, _tracking, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canSelectAll}}
    <div class="selectable-list-selection-tools">
      <div class="form-check">
        <Input class="form-check-input chk-select-all" @type="checkbox" @checked={{this.allSelected}} id="select-all-records" />
  
        <label class="form-check-label" for="select-all-records">
          {{t "select all"}}
        </label>
      </div>
    </div>
  {{/if}}
  
  {{#each this.value as |item|}}
    {{#unless item.isDeleted}}
      {{yield item this.selection this.onSelect}}
    {{/unless}}
  {{/each}}
  
  <LazyListLoadMore @lazyList={{@value}} class="load-more" />
  
  {{#if this.selection}}
    <Manage::ActionsSelected @actions={{this.actions}} @selected={{this.selection}} @onAction={{this.action}} @onDelete={{this.delete}} class="w-100"/>
  {{/if}}
  
  */
  {
    "id": "6XPnmaPp",
    "block": "[[[41,[30,0,[\"canSelectAll\"]],[[[1,\"  \"],[10,0],[14,0,\"selectable-list-selection-tools\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"form-check-input chk-select-all\"],[24,1,\"select-all-records\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"allSelected\"]]]],null],[1,\"\\n\\n      \"],[10,\"label\"],[14,0,\"form-check-label\"],[14,\"for\",\"select-all-records\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"select all\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"value\"]]],null]],null],null,[[[41,[51,[30,1,[\"isDeleted\"]]],[[[1,\"    \"],[18,3,[[30,1],[30,0,[\"selection\"]],[30,0,[\"onSelect\"]]]],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"\\n\"],[8,[39,7],[[24,0,\"load-more\"]],[[\"@lazyList\"],[[30,2]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"selection\"]],[[[1,\"  \"],[8,[39,8],[[24,0,\"w-100\"]],[[\"@actions\",\"@selected\",\"@onAction\",\"@onDelete\"],[[30,0,[\"actions\"]],[30,0,[\"selection\"]],[30,0,[\"action\"]],[30,0,[\"delete\"]]]],null],[1,\"\\n\"]],[]],null]],[\"item\",\"@value\",\"&default\"],false,[\"if\",\"input\",\"t\",\"each\",\"-track-array\",\"unless\",\"yield\",\"lazy-list-load-more\",\"manage/actions-selected\"]]",
    "moduleName": "manage/components/manage/selectable-list.hbs",
    "isStrictMode": false
  });
  let ManageSelectableListComponent = (_class = class ManageSelectableListComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "selection", _descriptor3, this);
    }
    get allValues() {
      if (Array.isArray(this.args.value?.items)) {
        return this.args.value.items;
      }
      return this.args.value ?? [];
    }
    get allSelected() {
      return this.selection == 'all';
    }
    set allSelected(value) {
      this.selection = value ? 'all' : (0, _array.A)();
    }
    get canSelectAll() {
      return this.allValues.length > 1 || this.selection == 'all';
    }
    get selectedValues() {
      if (this.allSelected) {
        return this.allValues;
      }
      return this.selection?.map?.(a => this.allValues.find(b => b.id == a))?.filter?.(a => a) ?? [];
    }
    toActionObject(name) {
      if (name == 'publish') {
        return {
          name: this.intl.t('publish'),
          key: 'publish',
          icon: 'eye',
          class: 'btn-secondary'
        };
      }
      if (name == 'unpublish') {
        return {
          name: this.intl.t('unpublish'),
          key: 'unpublish',
          icon: 'eye-slash',
          class: 'btn-secondary'
        };
      }
      if (name == 'pending') {
        return {
          name: this.intl.t('set as pending'),
          key: 'pending',
          icon: 'clock',
          class: 'btn-secondary'
        };
      }
    }
    get actions() {
      const result = [];
      for (const value of this.selectedValues) {
        for (const action of value.availableActions ?? []) {
          if (!result.includes(action)) {
            result.push(action);
          }
        }
      }
      return result.map(a => this.toActionObject(a)).filter(a => a);
    }
    get firstRecord() {
      return this.allValues[0];
    }
    get modelName() {
      return this.firstRecord?.constructor?.modelName;
    }
    get value() {
      if (Array.isArray(this.args.value?.items)) {
        return this.args.value.items;
      }
      return this.args.value;
    }
    async action(actionName) {
      if (actionName == 'deleteAll') {
        return this.deleteAll();
      }
      if (this.allSelected) {
        await this.selectedValues[0][`${actionName}Many`](this.args.query);
        return this.args.reload?.();
      }
      let promises = [];
      for (const value of this.selectedValues) {
        promises.push(value[actionName]());
      }
      await Promise.all(promises);
      return this.args.reload?.();
    }
    async deleteAll() {
      try {
        await this.modal.confirm(this.intl.t(`delete all ${this.modelName}s`), this.intl.t(`delete-all-confirmation-message-${this.modelName}`));
      } catch (err) {
        return false;
      }
      await this.firstRecord.deleteMany(this.args.query);
      await this.args.reload?.();
    }
    async delete() {
      try {
        const name = this.allValues?.find(a => a.id == this.selection[0]).name ?? '';
        await this.modal.confirm(this.intl.t(`delete ${this.modelName}`, {
          size: this.selection.length
        }), this.intl.t('delete-confirmation-message', {
          size: this.selection.length,
          name
        }));
      } catch (err) {
        return false;
      }
      const promises = this.selection.map(id => this.allValues.find(a => a.id == id)).map(a => a.destroyRecord());
      await Promise.all(promises);
      await this.args.reload?.();
    }
    onSelect(value) {
      this.selection = value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "action", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "action"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype)), _class);
  _exports.default = ManageSelectableListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageSelectableListComponent);
});