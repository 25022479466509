define("map/components/map-interactive", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Map::Container
    class="attributions"
    @left={{true}}
    @bottom={{true}}
    @right={{true}}
  >
    {{#each this.attributions as |attribution|}}
      {{#if attribution.url}}
        <a
          target="_blank"
          href={{attribution.url}}
          rel="noopener noreferrer"
        >{{attribution.name}}</a>
      {{else}}
        <span>{{attribution.name}}</span>
      {{/if}}
    {{/each}}
  </Map::Container>
  */
  {
    "id": "RxffWZ66",
    "block": "[[[8,[39,0],[[24,0,\"attributions\"]],[[\"@left\",\"@bottom\",\"@right\"],[true,true,true]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"attributions\"]]],null]],null],null,[[[41,[30,1,[\"url\"]],[[[1,\"      \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[30,1,[\"url\"]]],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]]]],[1]],null]],[]]]]]],[\"attribution\"],false,[\"map/container\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "map/components/map-interactive.hbs",
    "isStrictMode": false
  });
  class MapInteractiveComponent extends _component2.default {}
  _exports.default = MapInteractiveComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapInteractiveComponent);
});