define("manage/components/chk-record-selection", ["exports", "@ember/component", "@glimmer/component", "@ember/array", "@ember/template-factory"], function (_exports, _component, _component2, _array, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @record.canEdit}}
    <div class="checkbox-container" ...attributes>
      {{! template-lint-disable require-input-label }}
      <Input
        @type="checkbox"
        class="form-check-input chk-selected"
        disabled={{eq @selection "all"}}
        @checked={{this.isSelected}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "o5aMZHgz",
    "block": "[[[41,[30,1,[\"canEdit\"]],[[[1,\"  \"],[11,0],[24,0,\"checkbox-container\"],[17,2],[12],[1,\"\\n\"],[1,\"    \"],[8,[39,1],[[24,0,\"form-check-input chk-selected\"],[16,\"disabled\",[28,[37,2],[[30,3],\"all\"],null]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"isSelected\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@record\",\"&attrs\",\"@selection\"],false,[\"if\",\"input\",\"eq\"]]",
    "moduleName": "manage/components/chk-record-selection.hbs",
    "isStrictMode": false
  });
  class ChkRecordSelectionComponent extends _component2.default {
    get record() {
      return this.args.record ?? {};
    }
    get isSelected() {
      if (this.args.selection == 'all') {
        return true;
      }
      return this.args.selection?.includes(this.record.id);
    }
    set isSelected(value) {
      let newValue = (0, _array.A)(Array.isArray(this.args.selection) ? this.args.selection : []);
      if (value) {
        newValue.addObject(this.record.id);
      }
      if (!value) {
        newValue = newValue.without(this.record.id);
      }
      return this.args.onSelect?.(newValue);
    }
  }
  _exports.default = ChkRecordSelectionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChkRecordSelectionComponent);
});