define("spaces/components/editor/picture-with-options", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="manage-editors-picture" ...attributes>
    <Input::Options::Frame @title={{t "option-panel.size-mode"}}>
      <Editor::Properties::Size @value={{this.size}} @onChange={{this.changeSize}} />
    </Input::Options::Frame>
  
    <Editor::Base::Container @value={{this.container}} @onChange={{this.changeContainer}} @withSizeModes={{true}} />
  </div>
  */
  {
    "id": "55rKSq9L",
    "block": "[[[11,0],[24,0,\"manage-editors-picture\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"option-panel.size-mode\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,0,[\"size\"]],[30,0,[\"changeSize\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@value\",\"@onChange\",\"@withSizeModes\"],[[30,0,[\"container\"]],[30,0,[\"changeContainer\"]],true]],null],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"input/options/frame\",\"t\",\"editor/properties/size\",\"editor/base/container\"]]",
    "moduleName": "spaces/components/editor/picture-with-options.hbs",
    "isStrictMode": false
  });
  let EditorPictureWithOptionsComponent = (_class = class EditorPictureWithOptionsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_size", _descriptor, this);
      _initializerDefineProperty(this, "_container", _descriptor2, this);
    }
    get size() {
      if (this._size) {
        return this._size;
      }
      return this.args.value?.size ?? {};
    }
    get container() {
      if (this._container) {
        return this._container;
      }
      return this.value.container ?? {};
    }
    get value() {
      return this.args.value ?? {};
    }
    triggerChange() {
      const data = {
        size: this.size,
        container: this.container
      };
      this.args.onChange?.(data);
    }
    changeContainer(value) {
      this._container = value;
      this.triggerChange();
    }
    changeSize(value) {
      this._size = value;
      this.triggerChange();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_size", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeContainer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeContainer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSize"), _class.prototype)), _class);
  _exports.default = EditorPictureWithOptionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorPictureWithOptionsComponent);
});