define("spaces-view/components/view/generic-list", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @disableWrapper}}
    {{#each @value as |record index|}}
      {{yield record index}}
    {{/each}}
  
    <div data-editor="generic-list" data-type="list" class="d-none" ...attributes>
    </div>
  {{else}}
    <div data-editor="generic-list" data-type="list" ...attributes>
      {{#each @value as |record index|}}
        {{yield record index}}
      {{/each}}
    </div>
  {{/if}}
  */
  {
    "id": "2b8VjUVP",
    "block": "[[[41,[30,1],[[[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"    \"],[18,8,[[30,3],[30,4]]],[1,\"\\n\"]],[3,4]],null],[1,\"\\n  \"],[11,0],[24,\"data-editor\",\"generic-list\"],[24,\"data-type\",\"list\"],[24,0,\"d-none\"],[17,5],[12],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,\"data-editor\",\"generic-list\"],[24,\"data-type\",\"list\"],[17,5],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"      \"],[18,8,[[30,6],[30,7]]],[1,\"\\n\"]],[6,7]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"@disableWrapper\",\"@value\",\"record\",\"index\",\"&attrs\",\"record\",\"index\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "spaces-view/components/view/generic-list.hbs",
    "isStrictMode": false
  });
  class ViewGenericListComponent extends _component2.default {}
  _exports.default = ViewGenericListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewGenericListComponent);
});