define("spaces/components/editor/attribute", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::ModelItem
    @value={{this.source}}
    @onChange={{fn this.change "source"}}
    @models={{array "feature" "event"}}
    @space={{@space}}
  >
  
    <Editor::Properties::OptionContainer @label={{t "option-panel.attribute-path"}} as |id|>
      <Input::Text
        class="form-control input-attribute-field"
        id={{id}}
        @value={{this.attributeField}}
        @onChange={{fn this.change "attributeField"}}
      />
    </Editor::Properties::OptionContainer>
  
  </Input::Options::ModelItem>
  
  <Input::Options::Frame class="title-style" @title={{t "option-panel.title"}}>
    <Editor::Properties::Title
      @value={{this.title}}
      @deviceSize={{@deviceSize}}
      @onChange={{fn this.change "title"}}
      @enableTextValue={{true}}
    />
  </Input::Options::Frame>
  
  <Input::Options::Frame @title={{t "option-panel.attribute-style"}} class="attribute-style">
    <Editor::Properties::Text
      @enableTextValue={{false}}
      @value={{this.attributeStyle}}
      @onChange={{fn this.change "attributeStyle"}}
    />
  </Input::Options::Frame>
  */
  {
    "id": "qbgrjwWR",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@onChange\",\"@models\",\"@space\"],[[30,0,[\"source\"]],[28,[37,1],[[30,0,[\"change\"]],\"source\"],null],[28,[37,2],[\"feature\",\"event\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@label\"],[[28,[37,4],[\"option-panel.attribute-path\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"form-control input-attribute-field\"],[16,1,[30,2]]],[[\"@value\",\"@onChange\"],[[30,0,[\"attributeField\"]],[28,[37,1],[[30,0,[\"change\"]],\"attributeField\"],null]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],[[24,0,\"title-style\"]],[[\"@title\"],[[28,[37,4],[\"option-panel.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],null,[[\"@value\",\"@deviceSize\",\"@onChange\",\"@enableTextValue\"],[[30,0,[\"title\"]],[30,3],[28,[37,1],[[30,0,[\"change\"]],\"title\"],null],true]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],[[24,0,\"attribute-style\"]],[[\"@title\"],[[28,[37,4],[\"option-panel.attribute-style\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,8],null,[[\"@enableTextValue\",\"@value\",\"@onChange\"],[false,[30,0,[\"attributeStyle\"]],[28,[37,1],[[30,0,[\"change\"]],\"attributeStyle\"],null]]],null],[1,\"\\n\"]],[]]]]]],[\"@space\",\"id\",\"@deviceSize\"],false,[\"input/options/model-item\",\"fn\",\"array\",\"editor/properties/option-container\",\"t\",\"input/text\",\"input/options/frame\",\"editor/properties/title\",\"editor/properties/text\"]]",
    "moduleName": "spaces/components/editor/attribute.hbs",
    "isStrictMode": false
  });
  let EditorAttributeComponent = (_class = class EditorAttributeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_source", _descriptor, this);
      _initializerDefineProperty(this, "_title", _descriptor2, this);
      _initializerDefineProperty(this, "_attributeStyle", _descriptor3, this);
      _initializerDefineProperty(this, "_attributeField", _descriptor4, this);
    }
    get source() {
      if (this._source) {
        return this._source;
      }
      return this.args.value?.data?.source ?? {};
    }
    get title() {
      if (this._title) {
        return this._title;
      }
      return this.args.value?.data?.title ?? {};
    }
    get attributeStyle() {
      if (this._attributeStyle) {
        return this._attributeStyle;
      }
      return this.args.value?.data?.attributeStyle ?? {};
    }
    get attributeField() {
      if (this._attributeField) {
        return this._attributeField;
      }
      return this.args.value?.data?.sourceAttribute?.field ?? "";
    }
    triggerChange() {
      return this.args.onChange?.({
        source: this.source,
        sourceAttribute: {
          field: this.attributeField
        },
        title: this.title,
        attributeStyle: this.attributeStyle
      });
    }
    change(key, value) {
      this[`_${key}`] = value;
      this.triggerChange();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_source", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_attributeStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_attributeField", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  _exports.default = EditorAttributeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorAttributeComponent);
});