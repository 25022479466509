define("spaces/services/space-context-menu", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object"], function (_exports, _service, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Selection = void 0;
  var _class, _descriptor, _descriptor2, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let Selection = (_class = class Selection {
    constructor(type, value) {
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "value", _descriptor2, this);
      this.type = type;
      this.value = value;
    }
    toJSON() {
      return {
        type: this.type,
        value: this.value
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.Selection = Selection;
  let SpaceContextMenuService = (_class3 = class SpaceContextMenuService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "contextX", _descriptor3, this);
      _initializerDefineProperty(this, "contextY", _descriptor4, this);
      _initializerDefineProperty(this, "selection", _descriptor5, this);
      _initializerDefineProperty(this, "active", _descriptor6, this);
    }
    get isVisible() {
      return this.contextX && this.contextY;
    }
    clear() {
      localStorage?.removeItem?.("space-clipboard");
      this.selection = null;
    }
    restore() {
      const value = localStorage?.getItem?.("space-clipboard");
      let deserialized;
      try {
        deserialized = JSON.parse(value ?? "");
      } catch (err) {
        this.selection = null;
        return;
      }
      if (deserialized?.type && deserialized?.value) {
        this.selection = new Selection(deserialized.type, deserialized.value);
      }
    }
    store() {
      localStorage?.setItem?.("space-clipboard", JSON.stringify(this.selection));
    }
    handle(id, type, ev) {
      if (!ev?.target.classList.contains("col-selectable") || ev?.target !== ev?.currentTarget) {
        return false;
      }
      this.restore();
      ev.preventDefault();
      this.contextX = ev.clientX;
      this.contextY = ev.clientY;
      this.active = new Selection(id, type);
      return false;
    }
    hide() {
      this.active = null;
      this.contextX = 0;
      this.contextY = 0;
      this.store();
    }
  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "contextX", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "contextY", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "active", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "clear"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "restore", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "restore"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "store", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "store"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handle", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handle"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "hide", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "hide"), _class3.prototype)), _class3);
  _exports.default = SpaceContextMenuService;
});