define("spaces/components/editor/event/dates", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='manage-editors-event-dates' ...attributes>
    <Input::Options::ModelItem @space={{@space}} @value={{this.source}} @onChange={{this.changeSource}} @model="event" />
  
    <Input::Options::Frame
      class='dates-style'
      @title={{capitalize (t 'style')}}
    >
      <Editor::Properties::Text
        @value={{this.style}}
        @deviceSize={{@deviceSize}}
        @onChange={{this.changeStyle}}
        @enableTextValue={{false}}
      />
    </Input::Options::Frame>
  </div>
  */
  {
    "id": "TaD/1+GF",
    "block": "[[[11,0],[24,0,\"manage-editors-event-dates\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@space\",\"@value\",\"@onChange\",\"@model\"],[[30,2],[30,0,[\"source\"]],[30,0,[\"changeSource\"]],\"event\"]],null],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"dates-style\"]],[[\"@title\"],[[28,[37,2],[[28,[37,3],[\"style\"],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@value\",\"@deviceSize\",\"@onChange\",\"@enableTextValue\"],[[30,0,[\"style\"]],[30,3],[30,0,[\"changeStyle\"]],false]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@space\",\"@deviceSize\"],false,[\"input/options/model-item\",\"input/options/frame\",\"capitalize\",\"t\",\"editor/properties/text\"]]",
    "moduleName": "spaces/components/editor/event/dates.hbs",
    "isStrictMode": false
  });
  let ManageEditorsEventDatesComponent = (_class = class ManageEditorsEventDatesComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_source", _descriptor, this);
      _initializerDefineProperty(this, "_style", _descriptor2, this);
    }
    get source() {
      if (this._source) {
        return this._source;
      }
      return this.args.value?.data?.source;
    }
    get style() {
      if (this._style) {
        return this._style;
      }
      return this.args.value?.data?.style;
    }
    triggerChange() {
      return this.args.onChange?.({
        source: this.source,
        style: this.style
      });
    }
    changeSource(value) {
      this._source = value;
      return this.triggerChange();
    }
    changeStyle(value) {
      this._style = value;
      return this.triggerChange();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_source", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_style", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeSource", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeStyle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeStyle"), _class.prototype)), _class);
  _exports.default = ManageEditorsEventDatesComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageEditorsEventDatesComponent);
});