define("spaces-view/components/state-filters/day-of-week", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "core/lib/page-state", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _pageState, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="state-filters-day-of-week" ...attributes>
    <Filters::Options
      @value={{if this.niceValue (t (concat "days.long." this.niceValue))}}
      @title={{this.title}}
      @onChange={{this.reset}}
    >
      <div>
        {{#each this.days as |day|}}
          <button class="btn btn-day d-inline-block m-1 {{if (eq day.value this.value) "btn-success" "btn-outline-secondary"}}" type="button" {{on "click" (fn this.change day.value)}}>
            {{t (concat "days.short." day.name)}}
          </button>
        {{/each}}
      </div>
    </Filters::Options>
  </div>
  */
  {
    "id": "ZXGZVgKm",
    "block": "[[[11,0],[24,0,\"state-filters-day-of-week\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\",\"@title\",\"@onChange\"],[[52,[30,0,[\"niceValue\"]],[28,[37,2],[[28,[37,3],[\"days.long.\",[30,0,[\"niceValue\"]]],null]],null]],[30,0,[\"title\"]],[30,0,[\"reset\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"days\"]]],null]],null],null,[[[1,\"        \"],[11,\"button\"],[16,0,[29,[\"btn btn-day d-inline-block m-1 \",[52,[28,[37,6],[[30,2,[\"value\"]],[30,0,[\"value\"]]],null],\"btn-success\",\"btn-outline-secondary\"]]]],[24,4,\"button\"],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"change\"]],[30,2,[\"value\"]]],null]],null],[12],[1,\"\\n          \"],[1,[28,[35,2],[[28,[37,3],[\"days.short.\",[30,2,[\"name\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"day\"],false,[\"filters/options\",\"if\",\"t\",\"concat\",\"each\",\"-track-array\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "spaces-view/components/state-filters/day-of-week.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2023 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let StateFiltersDayOfWeekComponent = (_class = class StateFiltersDayOfWeekComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "clickOutside", _descriptor2, this);
      _defineProperty(this, "allDays", ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'weekday', 'weekend']);
    }
    get title() {
      return this.args.options?.label ?? this.intl.t('day of week');
    }
    get days() {
      return this.allDays.map((name, value) => ({
        name,
        value
      }));
    }
    get value() {
      return parseInt(this.state.dow);
    }
    get niceValue() {
      return this.days.find(a => a.value == this.value)?.name;
    }
    get state() {
      return (0, _pageState.fromPageState)(this.args.state);
    }
    reset() {
      const newState = this.state;
      delete newState.dow;
      return this.args.onChangeState((0, _pageState.toPageState)(newState));
    }
    change(value) {
      const newState = this.state;
      newState.dow = value;
      return this.args.onChangeState((0, _pageState.toPageState)(newState));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clickOutside", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  _exports.default = StateFiltersDayOfWeekComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateFiltersDayOfWeekComponent);
});