define("manage/components/input/link-pair", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row" ...attributes>
    <div class="col">
      <Input::Text
        class="form-control"
        @value={{@value.source}}
        @onChange={{fn this.change "source"}}
      />
    </div>
    <div class="col">
      <Input::Link @value={{@value.destination}} @onChange={{fn this.change "destination"}} @space={{@space}} />
    </div>
  </div>
  */
  {
    "id": "40LDMhiL",
    "block": "[[[11,0],[24,0,\"row\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"form-control\"]],[[\"@value\",\"@onChange\"],[[30,2,[\"source\"]],[28,[37,1],[[30,0,[\"change\"]],\"source\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@onChange\",\"@space\"],[[30,2,[\"destination\"]],[28,[37,1],[[30,0,[\"change\"]],\"destination\"],null],[30,3]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\",\"@space\"],false,[\"input/text\",\"fn\",\"input/link\"]]",
    "moduleName": "manage/components/input/link-pair.hbs",
    "isStrictMode": false
  });
  let InputLinkPairComponent = (_class = class InputLinkPairComponent extends _component2.default {
    change(field, newValue) {
      let value = this.args.value;
      value[field] = newValue;
      return value;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  _exports.default = InputLinkPairComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputLinkPairComponent);
});