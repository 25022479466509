define("spaces-view/components/state-filters/event-repetition", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "core/lib/page-state", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _pageState, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="state-filters-event-repetition" ...attributes>
    <Filters::Options
      @value={{if this.value (t (concat "event-repetition." this.value))}}
      @title={{this.title}}
      @onChange={{this.change}}
    >
      {{#each this.options as |option|}}
        <button
          type="button"
          class="btn
            {{if (eq this.value option) 'btn-secondary' 'btn-outline-secondary'}}
            btn-value-{{option}}"
          {{on "click" (fn this.change option)}}
        >
          {{capitalize (t (concat "event-repetition." option))}}
        </button>
      {{/each}}
    </Filters::Options>
  </div>
  */
  {
    "id": "P5FN6qLN",
    "block": "[[[11,0],[24,0,\"state-filters-event-repetition\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\",\"@title\",\"@onChange\"],[[52,[30,0,[\"value\"]],[28,[37,2],[[28,[37,3],[\"event-repetition.\",[30,0,[\"value\"]]],null]],null]],[30,0,[\"title\"]],[30,0,[\"change\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"      \"],[11,\"button\"],[16,0,[29,[\"btn\\n          \",[52,[28,[37,6],[[30,0,[\"value\"]],[30,2]],null],\"btn-secondary\",\"btn-outline-secondary\"],\"\\n          btn-value-\",[30,2]]]],[24,4,\"button\"],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"change\"]],[30,2]],null]],null],[12],[1,\"\\n        \"],[1,[28,[35,9],[[28,[37,2],[[28,[37,3],[\"event-repetition.\",[30,2]],null]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"option\"],false,[\"filters/options\",\"if\",\"t\",\"concat\",\"each\",\"-track-array\",\"eq\",\"on\",\"fn\",\"capitalize\"]]",
    "moduleName": "spaces-view/components/state-filters/event-repetition.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2023 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let StateFiltersEventRepetitionComponent = (_class = class StateFiltersEventRepetitionComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "clickOutside", _descriptor2, this);
    }
    get title() {
      return this.args.options?.label ?? this.intl.t('repetition');
    }
    get options() {
      return ['norepeat', 'daily', 'weekly', 'monthly', 'yearly'].filter(a => this.args.options[a]);
    }
    get value() {
      return this.state.er;
    }
    get state() {
      return (0, _pageState.fromPageState)(this.args.state);
    }
    change(value) {
      const newState = (0, _pageState.toPageState)({
        ...this.state,
        er: value
      });
      this.args.onChangeState?.(newState);
      this.clickOutside.unsubscribe();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clickOutside", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  _exports.default = StateFiltersEventRepetitionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateFiltersEventRepetitionComponent);
});