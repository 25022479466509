define("spaces/components/editor/generic-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/array", "@ember/template-factory"], function (_exports, _component, _component2, _object, _array, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Editor::Properties::OptionContainer @label={{t "option-panel.list-count"}} as |id|>
    <Input::Text @type="number" @value={{this.listCount}} @onChange={{this.changeListCount}} class="text-list-count form-control" id={{id}} />
  </Editor::Properties::OptionContainer>
  */
  {
    "id": "zDNlCYoh",
    "block": "[[[8,[39,0],null,[[\"@label\"],[[28,[37,1],[\"option-panel.list-count\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"text-list-count form-control\"],[16,1,[30,1]]],[[\"@type\",\"@value\",\"@onChange\"],[\"number\",[30,0,[\"listCount\"]],[30,0,[\"changeListCount\"]]]],null],[1,\"\\n\"]],[1]]]]]],[\"id\"],false,[\"editor/properties/option-container\",\"t\",\"input/text\"]]",
    "moduleName": "spaces/components/editor/generic-list.hbs",
    "isStrictMode": false
  });
  let EditorGenericListComponent = (_class = class EditorGenericListComponent extends _component2.default {
    get listCount() {
      return this.args.value?.length ?? 0;
    }
    changeListCount(value) {
      if (isNaN(value)) {
        return;
      }
      let result = (0, _array.A)(this.args.value);
      if (value < this.args.value?.length ?? 0) {
        result = (0, _array.A)(result.slice(0, value));
      }
      while (result.length < parseInt(value)) {
        result.pushObject(null);
      }
      this.args.onChange(result);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "changeListCount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeListCount"), _class.prototype)), _class);
  _exports.default = EditorGenericListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorGenericListComponent);
});