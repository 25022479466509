define("spaces/components/editor/link", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Panel class="link-named" @config={{this.config}} @value={{@value}} @space={{@space}} @onChange={{@onChange}} />
  */
  {
    "id": "xmYXK66X",
    "block": "[[[8,[39,0],[[24,0,\"link-named\"]],[[\"@config\",\"@value\",\"@space\",\"@onChange\"],[[30,0,[\"config\"]],[30,1],[30,2],[30,3]]],null]],[\"@value\",\"@space\",\"@onChange\"],false,[\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/link.hbs",
    "isStrictMode": false
  });
  class EditorLinkComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", [{
        name: "link",
        type: "link",
        label: "destination"
      }, {
        name: "newTab",
        type: "bool",
        label: "open in new tab"
      }]);
    }
  }
  _exports.default = EditorLinkComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorLinkComponent);
});