define("spaces/components/editor/map", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <Input::Options::Panel @config={{this.config}} @value={{@value}} @onChange={{@onChange}} />
  */
  {
    "id": "BcK3xX5x",
    "block": "[[[1,\"\\n\"],[8,[39,0],null,[[\"@config\",\"@value\",\"@onChange\"],[[30,0,[\"config\"]],[30,1],[30,2]]],null]],[\"@value\",\"@onChange\"],false,[\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/map.hbs",
    "isStrictMode": false
  });
  class EditorMapComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", [{
        name: "mode",
        options: ["show all features", "use a map"]
      }, {
        name: "flyOverAnimation",
        type: "bool"
      }, {
        name: "featureHover",
        options: ["do nothing", "mouse pointer"]
      }, {
        name: "featureSelection",
        options: ["do nothing", "open details in panel", "show popup", "go to page"]
      }, {
        name: "enableMapInteraction",
        options: ["no", "always", "automatic"]
      }, {
        name: "enablePinning",
        type: "bool"
      }, {
        name: "restrictView",
        type: "bool"
      }, {
        name: "showUserLocation",
        type: "bool"
      }, {
        name: "showZoomButtons",
        type: "bool"
      }, {
        name: "showBaseMapSelection",
        type: "bool"
      }, {
        name: "showTitle",
        type: "bool"
      }, {
        name: "enableFullMapView",
        type: "bool"
      }]);
    }
  }
  _exports.default = EditorMapComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorMapComponent);
});