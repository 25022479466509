define("spaces/components/highlight-frame", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="highlight-frame {{concat @value.selectionType "-frame"}}" style={{@value.style}}>
  </div>
  
  {{!-- template-lint-disable no-invalid-interactive --}}
  <div style={{@value.titleStyle}} {{did-insert this.setupTitle}} {{on "click" this.select}} {{on "mouseover" this.activate}} class="highlight-title title-{{@value.normalizedName}} {{concat @value.selectionType "-highlight-title"}}">
    {{#if @value.gid}}
      <div class="global"><FaIcon @icon="globe" /></div>
    {{/if}}
  
    <div class="name">{{@value.name}}</div>
  
    {{#if this.showType}}
      <div class="type">{{@value.type}}</div>
    {{/if}}
  </div>
  */
  {
    "id": "DQONLhte",
    "block": "[[[10,0],[15,0,[29,[\"highlight-frame \",[28,[37,0],[[30,1,[\"selectionType\"]],\"-frame\"],null]]]],[15,5,[30,1,[\"style\"]]],[12],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,0],[16,5,[30,1,[\"titleStyle\"]]],[16,0,[29,[\"highlight-title title-\",[30,1,[\"normalizedName\"]],\" \",[28,[37,0],[[30,1,[\"selectionType\"]],\"-highlight-title\"],null]]]],[4,[38,1],[[30,0,[\"setupTitle\"]]],null],[4,[38,2],[\"click\",[30,0,[\"select\"]]],null],[4,[38,2],[\"mouseover\",[30,0,[\"activate\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"gid\"]],[[[1,\"    \"],[10,0],[14,0,\"global\"],[12],[8,[39,4],null,[[\"@icon\"],[\"globe\"]],null],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showType\"]],[[[1,\"    \"],[10,0],[14,0,\"type\"],[12],[1,[30,1,[\"type\"]]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@value\"],false,[\"concat\",\"did-insert\",\"on\",\"if\",\"fa-icon\"]]",
    "moduleName": "spaces/components/highlight-frame.hbs",
    "isStrictMode": false
  });
  let HighlightFrameComponent = (_class = class HighlightFrameComponent extends _component2.default {
    get showType() {
      if (!this.args.value.type) {
        return false;
      }
      return this.args.value.name != this.args.value.type;
    }
    select() {
      return this.args?.onSelect?.(this.args.value);
    }
    setupTitle(element) {
      const bbox = element.getBoundingClientRect();
      this.args.value.titleWidth = Math.ceil(bbox.width);
    }
    activate() {
      this.args.value.isPrimary = true;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTitle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "activate"), _class.prototype)), _class);
  _exports.default = HighlightFrameComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HighlightFrameComponent);
});