define("spaces-view/lib/page-model", ["exports", "@glimmer/tracking", "rsvp", "core/lib/page-state", "core/lib/slug"], function (_exports, _tracking, _rsvp, _pageState, _slug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validVariables = _exports.PageModel = _exports.PageGroup = _exports.PageData = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class3, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _class5, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let PageData = (_class = class PageData {
    constructor() {
      _initializerDefineProperty(this, "changeCount", _descriptor, this);
      _initializerDefineProperty(this, "selectedModel", _descriptor2, this);
      _initializerDefineProperty(this, "selectedType", _descriptor3, this);
      _initializerDefineProperty(this, "selectedId", _descriptor4, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "changeCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.PageData = PageData;
  const validVariables = ['feature', 'map', 'campaign', 'icon', 'icon-set', 'article'];
  _exports.validVariables = validVariables;
  let PageGroup = (_class3 = class PageGroup {
    constructor() {
      _initializerDefineProperty(this, "path", _descriptor5, this);
      _initializerDefineProperty(this, "page", _descriptor6, this);
      _initializerDefineProperty(this, "store", _descriptor7, this);
      _initializerDefineProperty(this, "data", _descriptor8, this);
      _initializerDefineProperty(this, "pageId", _descriptor9, this);
    }
    get slug() {
      return this.page?.slug ?? '';
    }
    get hasVariable() {
      return this.slug.includes(':');
    }
    get variableName() {
      return (0, _slug.getVariablesFromPath)(this.slug)[0] ?? '';
    }
    get variableValue() {
      let value;
      const pathPieces = this.path?.split?.('/').filter?.(a => a.trim()) ?? [];
      const slugPieces = this.slug?.split?.('--') ?? [];
      slugPieces.forEach((item, index) => {
        if (!item.includes(':')) {
          return;
        }
        value = pathPieces[index];
      });
      return value ?? '';
    }
    get hasUnknownVariableValue() {
      return this.variableValue.startsWith(':');
    }
    async fetchSelectedModel() {
      if (this.store.isDestroyed) {
        return;
      }
      if (!this.hasVariable) {
        return;
      }
      const name = this.variableName.replace(':', '').replace('-id', '');
      const id = this.variableValue;
      if (!name) {
        return;
      }
      if (!this.variableValue) {
        return;
      }
      let record = this.store.peekRecord(name, id);
      if (!record) {
        record = await this.store.queryRecord(name, {
          id
        });
      }
      this.data[name] = record;
      this.data.selectedModel = record;
      this.data.selectedType = name;
      this.data.selectedId = id;
    }
    async ensurePage() {
      if (this.page) {
        return;
      }
      this.page = await this.store.findRecord('page', this.pageId);
    }
    async fetch() {
      if (!this.pageId) {
        return;
      }
      await this.ensurePage();
      this.page.fetchColsWithoutParams(this.data);
      await this.fetchSelectedModel();
      this.page.fetchColsWithParams(this.data);
      const loadedData = await (0, _rsvp.hash)(this.data);
      for (const key of Object.keys(loadedData)) {
        this.data[key] = loadedData[key];
      }
      this.data.page = this.page;
      this.data.changeCount++;
    }
  }, (_descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "path", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "store", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new PageData();
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "pageId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3);
  _exports.PageGroup = PageGroup;
  let PageModel = (_class5 = class PageModel {
    constructor(store, space, path) {
      _initializerDefineProperty(this, "space", _descriptor10, this);
      _initializerDefineProperty(this, "store", _descriptor11, this);
      _initializerDefineProperty(this, "headData", _descriptor12, this);
      _initializerDefineProperty(this, "main", _descriptor13, this);
      _initializerDefineProperty(this, "panel", _descriptor14, this);
      this.store = store;
      this.space = space;
      this.main.store = store;
      this.main.path = path;
      this.main.pageId = (0, _slug.toPageId)(path, this.space);
    }
    fillState(state) {
      (0, _pageState.fillPageStateInModel)(state, this.main.data);
      if (!this.main.canShowFeaturePanel) {
        this.panel = null;
        return;
      }
      if (!this.main.data['state-feature-id']) {
        this.panel = null;
        return;
      }
      if (!this.panel) {
        this.panel = new PageGroup();
      }
      this.panel.path = `/_panel/feature/${this.main.data['state-feature-id']}`;
      const pageId = (0, _slug.toPageId)(this.panel.path, this.space);
      if (this.panel?.pageId != pageId) {
        this.panel.page = null;
        this.panel.store = this.store;
        this.panel.pageId = pageId;
      }
    }
    async fetch() {
      await this.main.fetch();
      await this.panel?.fetch();
      if (!this.space) {
        this.space = await this.main.page.space;
      }
    }
    static clearCache() {
      this.cachedModel = null;
    }
    static async getCachedModel(path, store, space) {
      if (this.cachedModel?.main?.path != path || this.cachedModel?.space?.isDestroyed) {
        this.cachedModel = new PageModel(store, space, path);
      }
      return this.cachedModel;
    }
    static async getPageModelFromPath(path, state, store, space, router) {
      let pageModel = await PageModel.getCachedModel(path, store, space);
      if (!pageModel?.main?.pageId) {
        return;
      }
      await pageModel?.main?.ensurePage();
      if (pageModel?.main?.hasUnknownVariableValue) {
        const newPath = await (0, _slug.fillVariable)(path, pageModel?.main?.variableName, store);
        if (newPath) {
          router?.transitionTo?.(newPath);
          return {};
        }
        return;
      }
      pageModel.fillState(state);
      if (pageModel?.main?.pageId) {
        try {
          await pageModel.fetch();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          pageModel.main = null;
        }
      }
      return pageModel;
    }
  }, (_descriptor10 = _applyDecoratedDescriptor(_class5.prototype, "space", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class5.prototype, "store", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class5.prototype, "headData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class5.prototype, "main", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new PageGroup();
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class5.prototype, "panel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class5);
  _exports.PageModel = PageModel;
});