define("spaces/components/editor/source-field", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::ModelField @modelKey={{this.modelKey}} @model={{@model}} @for={{this.forField}} @value={{@value}} @onChange={{@onChange}} />
  */
  {
    "id": "qBHcVeD1",
    "block": "[[[8,[39,0],null,[[\"@modelKey\",\"@model\",\"@for\",\"@value\",\"@onChange\"],[[30,0,[\"modelKey\"]],[30,1],[30,0,[\"forField\"]],[30,2],[30,3]]],null]],[\"@model\",\"@value\",\"@onChange\"],false,[\"input/options/model-field\"]]",
    "moduleName": "spaces/components/editor/source-field.hbs",
    "isStrictMode": false
  });
  class EditorSourceFieldComponent extends _component2.default {
    get modelKey() {
      return this.args.element?.attributes?.getNamedItem?.("data-model-key")?.value;
    }
    get forField() {
      return this.args.element?.attributes?.getNamedItem?.("data-for")?.value ?? "description";
    }
  }
  _exports.default = EditorSourceFieldComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorSourceFieldComponent);
});