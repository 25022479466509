define("spaces-view/components/view/event/location", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "models/transforms/page-col-list", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _pageColList, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <View::Base::SourceRecord @path={{@path}} @model={{@model}} @value={{@value}} @onLoad={{this.loaded}}>
    {{#if this.textLocation}}
      <TextWithOptions @tag="div" class="event-location location-text" @deviceSize={{@deviceSize}} @options={{@value.data.title}}>
        <MarkDown @value={{this.textLocation}}/>
      </TextWithOptions>
    {{/if}}
  
    {{#if this.featureSource}}
      <View::Base::SourceRecord @model={{@model}} @value={{this.featureSource}} as |feature|>
        <Card::Any @value={{@value}} @record={{feature}} @deviceSize={{@deviceSize}} />
      </View::Base::SourceRecord>
    {{/if}}
  </View::Base::SourceRecord>
  */
  {
    "id": "ntTu2q4m",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@model\",\"@value\",\"@onLoad\"],[[30,1],[30,2],[30,3],[30,0,[\"loaded\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"textLocation\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"event-location location-text\"]],[[\"@tag\",\"@deviceSize\",\"@options\"],[\"div\",[30,4],[30,3,[\"data\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@value\"],[[30,0,[\"textLocation\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"featureSource\"]],[[[1,\"    \"],[8,[39,0],null,[[\"@model\",\"@value\"],[[30,2],[30,0,[\"featureSource\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@value\",\"@record\",\"@deviceSize\"],[[30,3],[30,5],[30,4]]],null],[1,\"\\n    \"]],[5]]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@path\",\"@model\",\"@value\",\"@deviceSize\",\"feature\"],false,[\"view/base/source-record\",\"if\",\"text-with-options\",\"mark-down\",\"card/any\"]]",
    "moduleName": "spaces-view/components/view/event/location.hbs",
    "isStrictMode": false
  });
  let PageColEventLocationComponent = (_class = class PageColEventLocationComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "fastboot", _descriptor2, this);
      _initializerDefineProperty(this, "pageCols", _descriptor3, this);
      _initializerDefineProperty(this, "record", _descriptor4, this);
    }
    get textLocation() {
      if (this.record?.location?.type != 'Text') {
        return null;
      }
      return this.record?.location?.value;
    }
    get featureSource() {
      if (this.record?.location?.type != 'Feature') {
        return null;
      }
      return new _pageColList.PageCol({
        data: {
          source: {
            model: 'feature',
            id: this.record?.location?.value
          }
        }
      }, this.store, this.fastboot);
    }
    loaded(record) {
      this.record = record;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pageCols", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "record", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loaded"), _class.prototype)), _class);
  _exports.default = PageColEventLocationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageColEventLocationComponent);
});